// 82335
.explore__heading {
    text-align: center;
    width: auto;
    margin: 0;
    font-family: $secondary-font-medium;
    font-size: 18px;
    line-height: 19px;
    color: $primary-darkest;
    font-weight: normal;
    letter-spacing: 0;
    @include media-breakpoint-up(md) {
        text-align: center;
        font-size: 36px;
        line-height: 46px;
    }

    &:after {
        content: "";
        height: 2px;
        width: 64px;
        background: $yellow;
        margin-top: 10px;
        margin-bottom: 40px;
        margin-right: auto;
        margin-left: auto;
        display: block;
        @include media-breakpoint-up(md) {
            margin-top: 16px;
            margin-bottom: 43px;
        }
        @include media-breakpoint-down(sm) {
            margin-bottom: 16px;
        }
    }
}
.explore__sub {
    font-size: 16px;
    line-height: 26px;
    font-family: $primary-font-light;
    margin-bottom: 32px;
    text-align: center;
    color: $black;
    @include media-breakpoint-down(sm) {
        text-align: center;
        font-size: 12px;
        line-height: 16px;
        margin-bottom: 16px;
    }

    p {
        margin: 0;
    }
}
.nav-tabs {
    border-bottom: 1px solid $primary;
    margin-bottom: 40px;

    @media (max-width: 1200px) {
        border-bottom: 0 none;
    }
    @include media-breakpoint-down(sm) {
        margin: 0 -8px;
    }
    .nav-item {
        margin-right: 48px;
        &:last-child {
            margin-right: 0;
        }
        @media (max-width: 1200px) {
            border-bottom: 0 none;
            margin: 0 24px 12px 24px;
        }

        @include media-breakpoint-down(sm) {
            font-size: 12px;
            margin: 0 8px 12px 8px;
        }

        .nav-link {
            height: 100%;
            font-size: 18px;
            font-weight: bold;
            line-height: 26px;
            letter-spacing: normal;
            text-align: center;
            color: $gray !important;
            border-bottom: 3px solid transparent;
            font-family: $primary-font-bold;
            position: relative;
            bottom: -2px;
            padding: 0 0 9px 0;

            @include media-breakpoint-down(sm) {
                font-size: 12px;
                line-height: 19px;
                padding-bottom: 5px;
            }

            &.active {
                border-bottom: 3px solid $yellow;
                background-color: $white;
            }

            &:hover,
            &:focus {
                border-color: $yellow;
            }
        }

        &.show .nav-link {
            border-color: $yellow;
        }
    }
}

.tile.more-tiles {
    .more-tiles__link {
        text-align: center;
        .more-title {
            &.p-xs {
                @media (max-width: 420px) {
                    padding-top: 40px;
                }
            }
        }
    }
}
